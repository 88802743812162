

.AutoComplete {
  display: flex;
  flex-wrap: wrap;
  --color-autocomplete-color: rgba(17, 24, 39, 1);
  --color-autocomplete-background: rgba(255, 255, 255, 1);
  --color-autocomplete-error: #d32f2f;
  --color-autocomplete-border-color: rgba(17, 24, 39, .1);
  --color-autocomplete-secondary: rgba(31, 41, 55, 1);
  --color-autocomplete-highlight: rgba(135, 220, 255, .2);
  --color-autocomplete-marker: rgba(55, 65, 81, 1);
  --color-autocomplete-box-shadow:  2px 2px 10px rgba(55, 65, 81, .5);
  --color-autocomplete-item-name: rgba(17, 24, 39, 1);
  --color-autocomplete-item-tag: #1565c0;
  --z-index-autocomplete: 100;
}
.AutoComplete_textBox.hasError {
  color: var(--color-autocomplete-error);
  border-color: var(--color-autocomplete-error);
}
.AutoComplete_textBox {
  border-radius: 0.25rem;
  width: 100%;
  background-color: transparent;
}
.AutoComplete_textBox:focus {
  outline-color: #1565c0;
}
.AutoComplete_error {
  margin: .25rem 0;
  padding: 0;
  color: var(--color-autocomplete-error);
  font-size: .75rem;
  font-weight: 400;
  display: block;
  width: 100%;
}

.AutoComplete___textBox::-webkit-input-placeholder,
.AutoComplete___textBox:-ms-input-placeholder,
.AutoComplete___textBox::placeholder {
  color: rgba(107, 114, 128, 1);
}

.AutoComplete___textBox:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.AutoComplete {
  position: relative;
  margin-top: 0.5rem;
  flex-grow: 1;
}


.AutoComplete_panel {
  position: absolute;
  z-index: var(--z-index-autocomplete);
  left: 0px;
  min-width: 15rem;
  max-width: 20rem;
  border-radius: 0.5rem;
  background-color: var(--color-autocomplete-background);
  box-shadow: 2px 2px 10px rgba(55, 65, 81, .5);
  box-shadow: var(--color-autocomplete-box-shadow);
  border-radius: 0.25rem;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.AutoComplete_panel___open {
  opacity: 1;
}



.AutoComplete_items {
  margin-top: 0.5rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 100%;
  max-height: 24rem;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}


/*
 *  SCROLL
 */

 .AutoComplete_items::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   background-color: var(--color-autocomplete-background);
  }
  
  .AutoComplete_items::-webkit-scrollbar
  {
    width: 6px;
    background-color: var(--color-autocomplete-background);
  }
  
  .AutoComplete_items::-webkit-scrollbar-thumb
  {
    background-color: var(--color-autocomplete-color);
 }
 

.AutoComplete_item {
  display: flex;
  align-items: flex-start;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: .5rem;
  background: transparent;
	border-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid var(--color-autocomplete-border-color);
}


.AutoComplete_item[aria-selected="true"] {
  background-color: var(--color-autocomplete-highlight);
}

.AutoComplete_item:hover, .AutoComplete_item:focus {
  background-color: var(--color-autocomplete-highlight);
}

.AutoComplete_itemName {
  font-weight: 600;
  color: var(--color-autocomplete-item-name);
}

mark {
  border-radius: 0.125rem;
  background-color: transparent;
  color: var(--color-autocomplete-color);
}

.AutoComplete_itemTag {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-autocomplete-item-tag);
}